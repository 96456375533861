<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
      :background-color="sidebarBackground"
      short-title="Omni-UP Backoffice"
      title="Omni-UP Backoffice"
    >
      <template v-slot:links>
        <sidebar-item
          :link="{
            name: 'Usuarios',
            icon: 'ni ni-single-02 text-yellow',
            path: '/usuarios',
          }"
        />

        <sidebar-item
          :link="{
            name: 'Especialidades',
            icon: 'ni ni-paper-diploma text-blue',
            path: '/especialidades',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Divisiónes',
            icon: 'ni ni-ambulance text-red',
            path: '/divisiones',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Líneas',
            icon: 'ni ni-badge text-orange',
            path: '/lineas',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Actividades',
            icon: 'ni ni-calendar-grid-58 text-info',
            path: '/actividades',
          }"
        />
        <!-- <sidebar-item
          :link="{
            name: 'Templates',
            icon: 'ni ni-email-83 text-primary',
            path: '/templates',
          }"
        /> -->
        <sidebar-item
          :link="{
            name: 'Carga de data',
            icon: 'ni ni-tv-2 text-primary',
            path: '/cargas',
          }"
        />
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <!-- your content here -->
        <router-view></router-view>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
  },
  data() {
    return {
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
<style lang="scss"></style>
