<template>
    <base-header type="gradient-success" class="pb-5 pt-5 pt-md-8">
      
    </base-header>

    <div class="container-fluid mt--7">
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Carga de Datos</h4>
            <p id="p-errors" v-if="errors.length">
              <b>Por favor, corrija el(los) siguiente(s) error(es):</b>
              <ul>
                <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
              </ul>
            </p>
            <form action @submit.prevent="subirCarga">
              <div class="form-group">
                <div class="input-group">
                  <VueMultiselect
                   :multiple="false" :options="options" :searchable="false" :close-on-select="true" :show-labels="false" placeholder="Seleccione un Tipo de Carga"
                   :custom-label="customLabel"
                    v-model="tipoCarga">
                  </VueMultiselect>
                </div>
              </div>
              <div class="form-group">
                <div class="input-group">
                  <input type="file" id="archivo" name="archivo" class="d-block" @change="fileChange" placeholder="Archivo de Carga">
                </div>
              </div>
              <div class="form-group">
                <!-- <div class="input-group">
                  <h4><a :href="S3_URL + 'base_templates.csv'" target="_blank">Descargar Formato - Templates</a></h4>
                </div> -->
                <div class="input-group">
                  <h4><a :href="S3_URL + 'base_rep.csv'" target="_blank">Descargar Formato - Base REP</a></h4>
                </div>
                <div class="input-group">
                  <h4><a :href="S3_URL + 'base_cm.csv'" target="_blank">Descargar Formato - Base CM</a></h4>
                </div>
              </div>
              <div class="form-group">
                <button class="btn btn-primary submit-btn">Enviar</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script lang="js">
import axios from 'axios'
import VueMultiselect from 'vue-multiselect'

export default {
  name: 'cargas',
  data () {
    return {
      tipoCarga: null,
      archivo: null,
      options: [{ value: 1, name : 'Carga de Templates', url: "importar-templates" }],
      //options: [{ value: 1, name : 'Carga de Templates', url: "importar-templates" }, { value: 2, name : 'Carga Base REP', url: "importar-detalle-rep" }, { value: 3, name : 'Carga Base CM', url: "importar-detalle-cm" }],
      errors: [],
      noFiles: true,
      S3_URL: process.env.VUE_APP_S3_URL
    }
  },
  components: {
    VueMultiselect
  },
  methods: {
    subirCarga () {
      let archivo = this.archivo
      this.errors = []
      let router = this.$router
      var tipoCarga = null
      var urlCarga = ''
      if(this.tipoCarga){
      tipoCarga = this.tipoCarga.value
      urlCarga = this.tipoCarga.url
      }
      
      if (!tipoCarga) {
        this.errors.push('Debe seleccionar un tipo de carga.')
      }
      //console.log(this.tipoCarga)
      //console.log(this.tipoCarga.value)
      // Si es tipo envio con ARCHIVO
      if (this.noFiles) {
        this.errors.push('El archivo de carga es obligatorio.')
      }

      var bodyFormData = new FormData()
      //bodyFormData.append('uploaded_file', document.getElementById('archivo').files[0])
      bodyFormData.append('url_file','s3://abbott-seguimiento-email-dev-storage-1lc22yf9x263p/imported_files/base_rep.csv');

      if (this.errors.length > 0) {
        setTimeout(function () {
          const el = document.getElementById('p-errors')
          if (el) {
            el.scrollIntoView({ behavior: 'smooth', block: 'center' })
          }
        }, 200)
      } else {
        if (confirm('¿Está seguro de proceder con la importación?. Se eliminarán los datos anteriores de la base.')) {
          let loader = this.$loading.show({
            // Optional parameters
            container: this.fullPage ? null : this.$refs.formContainer,
            canCancel: true,
            onCancel: this.onCancel
          })
          axios({
            method: 'post',
            url: process.env.VUE_APP_API_URL + 'backoffice/cargas/' + urlCarga,
            data: bodyFormData,
            headers:
              { 'Content-Type': 'multipart/form-data' }
          })
            .then(function (response) {
              // handle success
              console.log(response)
              loader.hide()
              router.push('/cargas?mensaje=importado')
            })
            .catch(function (response) {
              // handle error
              loader.hide()
              console.log(response)
            })
        }
      }
    },
    fileChange () {
      this.noFiles = !event.target.files.length
    },
    customLabel ({ name }) {
      return `${name}`
    }
  },
  mounted () {
  }
}
</script>

<style scoped lang="scss">
  #app-editor{
    width: 100%;
  }
  input[type="file"]{
    padding-top:11px;
  }
  #p-errors{
    color: red;
  }
</style>
