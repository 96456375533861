<template>
    <base-header type="gradient-success" class="pb-5 pt-5 pt-md-8">
      
    </base-header>

    <div class="container-fluid mt--7">
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Edición de Actividad</h4>
            <p id="p-errors" v-if="errors.length">
              <b>Por favor, corrija el(los) siguiente(s) error(es):</b>
              <ul>
                <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
              </ul>
            </p>
            <form action @submit.prevent="grabarActividad">
              <div class="form-group">
                <div class="input-group">
                  <VueMultiselect
                   :multiple="false" :options="tipoEnvios" :searchable="false" :close-on-select="true" :show-labels="false" placeholder="Seleccione un Tipo de Envío"
                   :custom-label="customLabelTipo"
                    v-model="tipoEnvio">
                  </VueMultiselect>
                </div>
              </div>
              <div class="form-group">
                <div class="input-group">
                  <input type="text" class="form-control" v-model="nombre" placeholder="Nombre">
                </div>
              </div>
              <div class="form-group">
                <div class="input-group">
                  <input type="number" class="form-control" v-model="duracion" placeholder="Duración">
                </div>
              </div>
              <div class="form-group">
                <div class="input-group">
                  <input type="number" class="form-control" v-model="duracionAlerta" placeholder="Duración Alerta">
                </div>
              </div>
              <div class="form-group">
                <div class="input-group">
                  <input type="text" class="form-control" v-model="color" placeholder="Color">
                </div>
              </div>
              <div class="form-group">
                <div class="input-group">
                  <input type="text" class="form-control" v-model="emailTemplate" placeholder="Email Template">
                </div>
              </div>
              <div class="form-group">
                <div class="input-group">
                  <h6>¿Es una actividad de envío?</h6>
                </div>
                <div class="input-group">
                  <Toggle v-model="actividadEnvio" color="primary" />
                </div>
              </div>
              <div class="form-group">
                <div class="input-group">
                  <h6>Usuarios asignados</h6>
                </div>
                <div class="input-group">
                  <VueMultiselect
                   :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Asigne los usuarios"
                    :custom-label="nombresemail"
                    v-model="seleccionados"
                    :options="usuarios"
                    track-by="nombres">
                  </VueMultiselect>
                </div>
              </div>
              <div class="form-group">
                <button class="btn btn-primary submit-btn">Enviar</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script lang="js">
import axios from 'axios'
import Toggle from "@vueform/toggle";
import VueMultiselect from 'vue-multiselect'

export default {
  name: 'editarActividad',
  data () {
    return {
      nombre: "",
      duracion: 0,
      duracionAlerta: 0,
      color: "",
      emailTemplate: "",
      actividadEnvio: false,
      tipoEnvio: { value: "E" },
      usuarios: [],
      seleccionados: [],
      tipoEnvios: [{ value: "E" }, { value: "S" }],
      // tipoEnvios: [{ value: "E", name : 'Email' }, { value: "S", name : 'SMS' }],
      errors: []
    }
  },
  components: {
    Toggle,
    VueMultiselect
  },
  methods: {
    grabarActividad () {
      let id = this.$route.params.id
      let nombre = this.nombre
      let duracion = this.duracion
      let duracionAlerta = this.duracionAlerta
      let color = this.color
      let emailTemplate = this.emailTemplate
      let actividadEnvio = this.actividadEnvio
      let seleccionados = this.seleccionados
      let tipoEnvio = this.tipoEnvio.value
      this.errors = []
      let router = this.$router
      console.log(this.seleccionados)

      if (!this.nombre) {
        this.errors.push('El nombre de actividad es obligatorio.')
      }

      if (this.errors.length > 0) {
        setTimeout(function () {
          const el = document.getElementById('p-errors')
          if (el) {
            el.scrollIntoView({ behavior: 'smooth', block: 'center' })
          }
        }, 200)
      } else {
        axios
          .put(process.env.VUE_APP_API_URL + 'backoffice/actividad/edit', { id: id, nombre: nombre, duracion: duracion, duracionAlerta: duracionAlerta, emailTemplate: emailTemplate, color: color, actividadEnvio: actividadEnvio, tipoEnvio: tipoEnvio, usuarios: seleccionados })
          .then((response) => {
            console.log(response)
            router.push('/actividades?mensaje=actualizado')
          })
          .catch(err => console.log(err))
      }
    },
    nombresemail ({ nombres, apellidos, email }) {
      return `${nombres} ${apellidos} — [${email}]`
    },
    customLabelTipo ({ value }) {
      if (value == "S") {
        return "SMS"
      }
        return "Email"
    }
  },
  mounted () {
    let id = this.$route.params.id
    axios
      .get(process.env.VUE_APP_API_URL + 'backoffice/usuarios/all')
      .then((response) => {
        console.log(response.data)
        this.usuarios = response.data
        axios
          .post(process.env.VUE_APP_API_URL + 'backoffice/actividad/detail', {id: id})
          .then((response) => {
            this.nombre = response.data.nombre
            this.seleccionados = response.data.actividad_usuarios
            this.duracion = response.data.duracion
            this.duracionAlerta = response.data.duracionAlerta
            this.color = response.data.color
            this.emailTemplate = response.data.emailTemplate
            this.tipoEnvio = { value: response.data.tipoEnvio }
            if(response.data.actividadEnvio === 1){
              this.actividadEnvio = true
            }else{
              this.actividadEnvio = false
            }
          })
      })
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style scoped lang="scss">
  #app-editor{
    width: 100%;
  }
  input[type="file"]{
    padding-top:11px;
  }
  #p-errors{
    color: red;
  }
</style>
