import { createRouter, createWebHashHistory } from "vue-router";
// import store from "../store"

import DashboardLayout from "@/layout/DashboardLayout";
import AuthLayout from "@/layout/AuthLayout";

import Dashboard from "../views/Dashboard.vue";
import Tables from "../views/Tables.vue";

import Login from "../views/Login.vue";
import Logout from "../views/Logout.vue";
import Usuarios from "../views/usuarios/index.vue";
import UsuariosRegistro from "../views/usuarios/registrar.vue";
import UsuariosEditar from "../views/usuarios/editar.vue";
import Especialidades from "../views/especialidades/index.vue";
import EspecialidadesRegistro from "../views/especialidades/registrar.vue";
import EspecialidadesEditar from "../views/especialidades/editar.vue";
import Divisiones from "../views/divisiones/index.vue";
import DivisionesRegistro from "../views/divisiones/registrar.vue";
import DivisionesEditar from "../views/divisiones/editar.vue";
import Lineas from "../views/lineas/index.vue";
import LineasRegistro from "../views/lineas/registrar.vue";
import LineasEditar from "../views/lineas/editar.vue";
import Actividades from "../views/actividades/index.vue";
import ActividadesEditar from "../views/actividades/editar.vue";
import Templates from "../views/templates/index.vue";
import Cargas from "../views/cargas.vue";

const routes = [
  {
    path: "/",
    redirect: "/usuarios",
    meta: {
        title: "Home",
        requiresAuth: true
    },
    component: DashboardLayout,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        components: { default: Dashboard },
      },
      {
        path: "/usuarios",
        name: "usuarios",
        components: { default: Usuarios },
      },
      {
        path: "/usuarios/registrar",
        name: "usuarios_registrar",
        components: { default: UsuariosRegistro },
      },
      {
        path: '/usuarios/editar/:id',
        name: "usuarios_editar",
        components: { default: UsuariosEditar },
      },
      {
        path: "/especialidades",
        name: "especialidades",
        components: { default: Especialidades },
      },
      {
        path: "/especialidades/registrar",
        name: "especialidades_registrar",
        components: { default: EspecialidadesRegistro },
      },
      {
        path: '/especialidades/editar/:id',
        name: "especialidades_editar",
        components: { default: EspecialidadesEditar },
      },
      {
        path: "/divisiones",
        name: "divisiones",
        components: { default: Divisiones },
      },
      {
        path: "/divisiones/registrar",
        name: "divisiones_registrar",
        components: { default: DivisionesRegistro },
      },
      {
        path: '/divisiones/editar/:id',
        name: "divisiones_editar",
        components: { default: DivisionesEditar },
      },
      {
        path: "/lineas",
        name: "lineas",
        components: { default: Lineas },
      },
      {
        path: "/lineas/registrar",
        name: "lineas_registrar",
        components: { default: LineasRegistro },
      },
      {
        path: '/lineas/editar/:id',
        name: "lineas_editar",
        components: { default: LineasEditar },
      },
      {
        path: "/actividades",
        name: "actividades",
        components: { default: Actividades },
      },
      {
        path: '/actividades/editar/:id',
        name: "actividades_editar",
        components: { default: ActividadesEditar },
      },
      {
        path: "/templates",
        name: "templates",
        components: { default: Templates },
      },
      {
        path: "/cargas",
        name: "cargas",
        components: { default: Cargas },
      },
      {
        path: "/tables",
        name: "tables",
        components: { default: Tables },
      },
    ],
  },
  {
    path: "/",
    redirect: "login",
    component: AuthLayout,
    children: [
      {
        path: "/login",
        name: "login",
        components: { default: Login },
      }
    ],
  },
  {
    path: "/",
    redirect: "Logout",
    component: AuthLayout,
    children: [
      {
        path: "/logout",
        name: "logout",
        components: { default: Logout },
      }
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  linkActiveClass: "active",
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // if (store.getters.isLoggedIn) {
    // console.log("TOKEN : " + localStorage.getItem("token"))
    if (localStorage.getItem("token") != "" && localStorage.getItem("token") != null) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})

export default router;
