/*!

=========================================================
* Vue Argon Dashboard - v2.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/vue-argon-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/vue-argon-dashboard/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import ArgonDashboard from "./plugins/argon-dashboard";
import Axios from "axios";
import "element-plus/lib/theme-chalk/index.css";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
// import Vue3ColorPicker from "vue3-colorpicker";
import "vue3-colorpicker/style.css";
import Toggle from "@vueform/toggle";
import "@vueform/toggle/themes/default.css";
import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/@fortawesome/fontawesome-free/css/fontawesome.min.css";
import "./assets/scss/argon.scss";
import Autocomplete from 'vue3-autocomplete'
// Optional: Import default CSS
import 'vue3-autocomplete/dist/vue3-autocomplete.css'

const appInstance = createApp(App);
appInstance.use(router);
appInstance.use(ArgonDashboard);
appInstance.use(Loading);
appInstance.use(Toggle);
appInstance.use(Autocomplete);
// appInstance.use(Vue3ColorPicker);

const token = localStorage.getItem("token");
appInstance.config.globalProperties.$http = Axios;
appInstance.config.globalProperties.$axios = Axios;
appInstance.config.globalProperties.$http.defaults.headers.common["Authorization"] = "Bearer " + token;

appInstance.mount("#app");
